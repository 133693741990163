import React, {useState} from 'react';
import {Button} from '../../../../components/UI/Buttons/Buttons'
import {TitleH3} from '../../../../components/UI/Titles/Titles'
import {api} from '../../../../services/api'

import {toast} from 'react-toastify';

import Modal from 'react-modal';

const customStyles = {
    overlay: {
        background: 'rgba(0,0,0,.5)'
    },
    content: {
        width: '460px',
        maxWidth: 'calc(100% - 40px)',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

type CreateUserProps = {
    closeModal: () => void,
    visibility: boolean
}

const CreateUser = ({closeModal, visibility}: CreateUserProps) => {
    const [user, setUser] = useState({});
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setLoading(true);
        try {

            await new Promise((resolve) => setTimeout(() => {
                resolve(true)
            }, 4000));

            await api.post('users/add', user);

            toast.success("Usuário adicionado.");
            closeModal();
        } catch (err) {
            setError('Houve um erro ao tentar adicionar usuário. Tente novamente.');
        }

        setLoading(false);
    }

    return (
        <Modal
            isOpen={visibility}
            contentLabel="Novo usuário"
            onRequestClose={closeModal}
            style={customStyles}
            ariaHideApp={false}
        >
            <button onClick={closeModal} className="absolute top-1.5 right-1.5">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
            <div className="w-full">
                <form className="bg-white rounded px-2 p1-6 pb-2 container" onSubmit={handleSubmit}>
                    <TitleH3>Adicionar usuário</TitleH3>
                    <div className="my-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Email
                        </label>
                        <input
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            required
                            onChange={e => setUser({...user, 'email': e.target.value})}/>
                    </div>

                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Tipo
                        </label>
                        <select className="order rounded w-full py-2 px-3 text-gray-700 leading-tight"
                                required
                                onChange={e => setUser({...user, 'type': e.target.value})}>
                            <option value="default">Padrão</option>
                            <option value="admin">Administrador</option>
                        </select>
                    </div>

                    <div className="flex items-center justify-center mb-6">
                        <Button disabled={loading}>Adicionar</Button>
                    </div>

                    {error &&
                    <div className="flex items-center justify-center">
                        <p className="text-sm text-red-700 text-center">{error}</p>
                    </div>
                    }

                </form>
            </div>
        </Modal>
    )
}

export default CreateUser;