import {createTheme, MuiThemeProvider, Paper} from "@material-ui/core";

type PushPreviewProps = {
    notification: {
        type?: string,
        title?: string,
        app: string,
        text?: string,
        image?: string
    },
}

const site: ({
    Default: string,
    Unknown: string,
    Omelete_dev: string,
    Omelete_production: string,
    Omelete_staging: string,
    The_Enemy_dev: string,
    The_Enemy_production: string,
    The_Enemy_staging: string
} | any) = {
    Default: "exemplo.com.br",
    Unknown: "exemplo.com.br",
    Omelete_dev: "localhost:5000",
    Omelete_staging: "staging.omelete.com.br",
    Omelete_production: "omelete.com.br",
    The_Enemy_dev: "localhost:5000",
    The_Enemy_staging: "staging.theenemy.com.br",
    The_Enemy_production: "theenemy.com.br"
}

const deviceLogo: ({} | any) = {
    chrome: "/chrome_icon.png",
    firefox: "/firefox_icon.png",
    android: "",
    iphone: "",
    default: ""
}

const appIcon: ({} | any) = {
    Default: "/o&co_icon.png",
    Unknown: "/o&co_icon.png",
    Omelete_dev: "/omelete_icon.png",
    Omelete_staging: "/omelete_icon.png",
    Omelete_production: "/omelete_icon.png",
    The_Enemy_dev: "/the_enemy_icon.png",
    The_Enemy_staging: "/the_enemy_icon.png",
    The_Enemy_production: "/the_enemy_icon.png"
}

const theme = createTheme({
    overrides: {
        MuiPaper: {
            root: {
                marginTop: "0px",
                backgroundColor: "#1f1f1fff",
                color: "#b4b4b4",
                borderRadius: "unset !important"
            }
        },
    }
});

export const PushPreview = ({notification}: PushPreviewProps) => (
    <>
        {(!!notification.type && notification.type === "windows") ? (
            <MuiThemeProvider theme={theme}>
                {!!notification.image ? (
                    <div className="push-windows-image">
                        <img src={notification.image} alt="Notification"/>
                    </div>
                ) : (<></>)
                }
                <Paper elevation={3} className="flex push-preview">
                    <img src={!!notification.app ? appIcon[notification.app] : appIcon.Default}
                         alt="Site Icon"
                         className="w-16 h-16 p-4"/>
                    <div className="flex flex-col pt-2 pb-4">
                        <p className="text-sm text-white font-bold truncate">{!!notification.title ? notification.title : "Exemplo de Título"}</p>
                        <p className="text-sm font-light">{!!notification.text ? notification.text : "No windows o texto da notificação é mostrado de forma completa e não escondida."}</p>
                        <p className="text-xs">Google Chrome • {site[notification.app]}</p>
                    </div>
                </Paper>
            </MuiThemeProvider>
        ) : (
            <Paper elevation={3} className="flex push-preview">
                <img src={!!notification.type ? deviceLogo[notification.type] : "/chrome_icon.png"} alt="Browser Icon"
                     className="w-16 h-16 p-4"/>
                <div className="flex flex-col">
                    <p className="text-base truncate">{!!notification.title ? notification.title : "Exemplo de Título"}</p>
                    <p className="text-xs">{site[notification.app]}</p>
                    <p className="text-sm truncate">{!!notification.text ? notification.text : "Uma notificação"}</p>
                </div>
                <img src={!!notification.app ? appIcon[notification.app] : "/image_placeholder.png"} alt="Placeholder"
                     className="w-8 h-8 m-auto placeholder"/>
            </Paper>
        )
        }
    </>
);