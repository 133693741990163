import Skeleton from "react-loading-skeleton";

const ListSkeleton = () =>
    <div className="skeleton-container">
      <ul className="pt-6 space-y-6 w-full">
        {Array(10)
          .fill('')
          .map((item, index) => (
            <li key={index}>
                <div className="p-3 border-2 border-gray-150 appearance-none rounded flex flex-col items-between bg-white w-full">
                  <Skeleton height={70} width={`100%`} />
                </div>
            </li>
        ))}
      </ul>
    </div>
  
  export default ListSkeleton;