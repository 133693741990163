type PaginationProps = {
    currentPage: number,
    pages: number[],
    handlePageChange: (pageIndex: number) => void
}

const Pagination = ({currentPage, pages, handlePageChange} : PaginationProps) => {

    return <ul className="pt-6 flex justify-end" style={{ minHeight: '48px'}}>
        { pages.map(pageIndex => 
            <li key={pageIndex}>
                <button className={`inline-block text-white px-2 rounded mx-1 ${currentPage === pageIndex ? 'bg-pink-500' : ' bg-pink-300'}`}
                    key={pageIndex} 
                    onClick={() => handlePageChange(pageIndex)}
                >
                    {pageIndex}
                </button>
            </li>
        )}
    </ul>
}

export default Pagination