import {useEffect, useState} from 'react'
import {api} from '../../../../services/api'
import {useHistory, useParams} from 'react-router-dom'

import {Button} from '../../../../components/UI/Buttons/Buttons'
import {ListItem, ListSkeleton, Pagination} from '../../../../components/UI/Listing/Listing'

import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

type User = {
    _id: number,
    status: string,
    type: string,
    fullName: string,
    email: string,
    createdAt: string
}

type UserListProps = {
    stopListReload: () => void,
    listReload: boolean
}

const UsersList = ({stopListReload, listReload}: UserListProps) => {
    const badgeType: any = {
        'new': 'bg-blue-200 text-blue-800',
        'active': 'bg-green-200 text-green-800',
        'inactive': 'bg-gray-200 text-gray-800',
        'suspended': 'bg-red-200 text-red-800',
        'error': 'bg-yellow-200 text-yellow-800'
    }

    const statusDict: any = {
        'new': 'Novo',
        'active': 'Ativo',
        'inactive': 'Inativo',
        'suspended': 'Suspenso',
        'error': 'Erro',
    }

    const {page} = useParams<{ page: string }>();
    const history = useHistory();

    const [pages, setPages] = useState<any[]>([]);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(Number(page) || 1);
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);

    async function getUsers(page: number) {
        stopListReload()
        setError('');
        setLoading(true);
        try {
            const {data} = await api.get('users', {params: {pageIndex: page}});
            await setUsers(data.users);
            let pagesArray = Array.from(Array(data.totalPages), (x, index) => index + 1);
            setPages(pagesArray);
        } catch (err) {
            if (!!err.response) {
                const {error} = err.response.data;

                if(error.codeValue === 0)
                    history.push('/login')

                if (error.codeValue === 1)
                    setError('A página que você está tentando acessar não existe.');
            } else {
                console.log(err);
                setError('Algo deu errado, tente recarregar a página.');
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        getUsers(currentPage).then();
        window.scrollTo(0, 0);
        //eslint-disable-next-line
    }, [currentPage, listReload])

    const handlePageChange = (pageIndex: number) => {
        setCurrentPage(pageIndex);
        history.push(`/dashboard/usuarios/${pageIndex}`);
    }

    return (
        <div className="w-full">
            <Pagination currentPage={currentPage} handlePageChange={handlePageChange} pages={pages}/>
            {loading ? <ListSkeleton/>
                : <>
                    <ul className="pt-6 space-y-6 w-full">
                        {users.map(user =>
                            <ListItem key={user._id}>
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-col">
                                        <p className="text-lg">{user.fullName}</p>
                                        <p className="text-sm text-gray-500 mt-0.5">{user.email}</p>
                                        <p className="text-xs mt-0.5">Adicionado {moment(user.createdAt).fromNow()}</p>
                                    </div>
                                    <p className={`badge text-sm px-3 ${badgeType[user.status]} rounded-full`}>{statusDict[user.status]}</p>
                                </div>
                            </ListItem>
                        )}
                    </ul>

                    {error &&
                    <div className="flex flex-col items-center justify-center">
                        <p className="text-sm text-red-700 text-center mb-4">{error}</p>
                        <Button onClick={() => {
                            handlePageChange(1)
                        }}>Retornar para a listagem</Button>
                    </div>
                    }
                </>
            }
            <Pagination currentPage={currentPage} handlePageChange={handlePageChange} pages={pages}/>
        </div>
    )
}

export default UsersList