type ListItemProps = {
    children: React.ReactNode
}

const ListItem = ({ children }: ListItemProps) => {

    return(
        <li className="relative p-3 border-2 border-gray-150 appearance-none rounded flex flex-col items-between bg-white w-full">
            {children}
        </li>
    )
}

export default ListItem