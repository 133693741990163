import React from 'react';
import ReactDOM from 'react-dom';
import * as sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';

sentry.init({
    dsn: "https://5780d45afbe545e590b79908763ac3e0@o61382.ingest.sentry.io/6114878",
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);