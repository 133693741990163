// import { useState } from 'react'
import { TitleH1 } from '../../../components/UI/Titles/Titles'

// import { Button } from '../../../components/UI/Buttons/Buttons'

import TopicsList from './List/List'
// import CreateTopic from './Create/Create';

const Topics = () =>{
    // const [modalIsOpen, setModalIsOpen] = useState(false);

    return <section className="px-8 py-6 w-full">
                <header className="flex justify-between	">
                    <TitleH1>Tópicos</TitleH1>
                    
                    <div>
                    {/*    <Button onClick={() => setModalIsOpen(true)} extendclassname="flex pl-2">*/}
                    {/*        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">*/}
                    {/*            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />*/}
                    {/*        </svg>*/}
                    {/*        Novo Tópico*/}
                    {/*    </Button>*/}
                    </div>
                </header>
                
                <TopicsList/>
                
                {/*<CreateTopic*/}
                {/*    visibility={modalIsOpen}*/}
                {/*    closeModal={() => setModalIsOpen(false)}*/}
                {/*/>*/}
            </section>

}

export default Topics