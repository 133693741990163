import {BrowserRouter, Redirect, Route} from 'react-router-dom'
import {Dashboard, Login} from './pages'
import {PrivateRoute} from "./components/PrivateRoute/PrivateRoute";
import {api} from "./services/api";

function App() {
    api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;
    return (
        <BrowserRouter>
            <Route path="/" exact><Redirect to="/login"/></Route>
            <Route path="/login" exact><Login/></Route>
            <PrivateRoute path="/dashboard" component={Dashboard}/>
        </BrowserRouter>
    );
}

export default App;