import { useState } from 'react'
import { TitleH1 } from '../../../components/UI/Titles/Titles'

import { Button } from '../../../components/UI/Buttons/Buttons'

import MessagesList from './List/List'
import CreateMessage from './Create/Create'

const Messages = () =>{
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [forceReload, setForceReload] = useState(false);

    const openModal = () => {
        // @ts-ignore
        document.querySelector('body').style.overflow = 'hidden';
        setModalIsOpen(true);
    }
    const closeModal = () => {
        // @ts-ignore
        document.querySelector('body').style.overflow = 'auto';
        setForceReload(true);
        setModalIsOpen(false);
        setTimeout(() => {setForceReload(false)}, 1);
    }

    return <section className="px-8 py-6 w-full">
                <header className="flex justify-between	">
                    <TitleH1>Mensagens</TitleH1>
                    
                    <div>
                        <Button onClick={() => openModal()} extendclassname="flex pl-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                            Nova mensagem
                        </Button>
                    </div>
                </header>

                {forceReload ? (<></>) : (<MessagesList/>)}
                
                <CreateMessage
                    visibility={modalIsOpen}
                    closeModal={() => closeModal()}
                />
            </section>
}

export default Messages