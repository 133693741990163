import {Redirect, Route, Switch} from 'react-router-dom'
import Sidebar from '../../components/Sidebar/Sidebar'

import {Messages, Topics, Users} from '../../pages'
import {ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import {useState} from "react";

const Dashboard = () => {
    const userPermissions: any|string = sessionStorage.getItem('userPermissions');
    const userAvatar: any|string = sessionStorage.getItem('userIcon');

    const [messagesAccess] = useState<boolean>(userPermissions.includes('all') || userPermissions.includes('default') || userPermissions.includes('messages'));
    const [topicsAccess] = useState<boolean>(userPermissions.includes('all') || userPermissions.includes('default') || userPermissions.includes('topics'));
    const [usersAccess] = useState<boolean>(userPermissions.includes('all') || userPermissions.includes('users'));

    return (
        <section className="w-full h-full flex bg-gray-100">
            <Sidebar profilePicture={userAvatar}/>
            <Switch>
                {messagesAccess ? ( <Route exact path="/dashboard/mensagens"><Messages/></Route> ) : (<Redirect to="/dashboard"/>)}
                {messagesAccess ? ( <Route path="/dashboard/mensagens/:page"><Messages/></Route> ) : (<Redirect to="/dashboard"/>)}
                {topicsAccess ? ( <Route exact path="/dashboard/topicos"><Topics/></Route> ) : (<Redirect to="/dashboard"/>)}
                {topicsAccess ? ( <Route path="/dashboard/topicos/:page"><Topics/></Route> ) : (<Redirect to="/dashboard"/>)}
                {usersAccess ? ( <Route path="/dashboard/usuarios"><Users/></Route> ) : (<Redirect to="/dashboard"/>)}
            </Switch>
            <ToastContainer theme='light'/>
        </section>
    )
}

export default Dashboard