import { useEffect } from 'react';

const clientId = process.env.REACT_APP_G_CLIENT_ID || '';

type GoogleLoginButtonProps = {
    onSuccess: (res: any) => void
}

const GoogleLoginButton = ({onSuccess} : GoogleLoginButtonProps) => {
    useEffect(() => {
        // @ts-ignore
        google.accounts.id.initialize({
            client_id: clientId,
            callback: onSuccess,
        });

        // @ts-ignore
        google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            { type: 'standard', theme: 'outline', size: 'large' }
        );
    }, [onSuccess]);
   
    return <div>
        <div id={"signInDiv"}></div>
    </div>
}

export default GoogleLoginButton;