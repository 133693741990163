import React, { forwardRef } from "react";

type CustomButton = {
    children: React.ReactNode,
    extendclassname?: string
}

export const Button = forwardRef(
    (props: React.ComponentPropsWithoutRef<"button"> & CustomButton, ref: React.Ref<HTMLButtonElement>) => 
        <button
            className={`bg-pink-500 disabled:opacity-10 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${props.extendclassname}`}
            ref={ref} {...props}>
                {props.children}
        </button>
);