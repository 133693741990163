import { Redirect, Route } from "react-router-dom";

type PrivateRouteProps = {
    path?: string,
    component: any
}

export const PrivateRoute = ({ component: Component, ...rest } : PrivateRouteProps) => (
  <Route
      {...rest}
      render={props =>
        localStorage.getItem("authToken") && sessionStorage.getItem("userPermissions") ? (
            <Component {...props} />
        ) : (
            <Redirect to={{
                pathname: "/login",
                state: { from: props.location }
                }}
            />
        )
      }
  />
);