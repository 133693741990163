import {useEffect, useState} from 'react'
import {api} from '../../../../services/api'
import {useHistory, useParams} from 'react-router-dom'
import moment from 'moment'

import {TitleH5} from '../../../../components/UI/Titles/Titles'
import {Button} from '../../../../components/UI/Buttons/Buttons'
import {ListItem, ListSkeleton, Pagination} from '../../../../components/UI/Listing/Listing';

type Notification = {
    title: string,
    body: string,
    image: string,
    clickAction: string
}

type Message = {
    _id: number,
    notification: Notification,
    createdAt: string,
    app: string,
    topic: string
    author: string
}

const MessagesList = () => {
    const { page } = useParams<{ page: string }>();
    const history = useHistory();
    
    const [pages, setPages] = useState<any[]>([]);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(Number(page) || 1);
    const [messages, setMessages] = useState<Message[]>([]);
    const [loading, setLoading] = useState(true);

    const siteUrlDict: ({} | any) = {
        "Unknown": "omeletecompany.com.br",
        "Omelete_production": "omelete.com.br",
        "Omelete_staging": "staging.omelete.com.br",
        "Omelete_dev": "omelete.localhost:5000",
        "The_Enemy_production": "theenemy.com.br",
        "The_Enemy_staging": "staging.theenemy.com.br",
        "The_Enemy_dev": "theenemy.localhost:5000"
    }

    const appBanner: ({} | any) = {
        "Unknown": "/o&co_icon.png",
        "Omelete_production_Default": "/omelete_banner_logo.jpg",
        "Omelete_staging_Default": "/omelete_banner_logo.jpg",
        "Omelete_dev_Default": "/omelete_banner_logo.jpg",
        "The_Enemy_production_Default": "/the_enemy_banner_logo.jpg",
        "The_Enemy_staging_Default": "/the_enemy_banner_logo.jpg",
        "The_Enemy_dev_Default": "/the_enemy_banner_logo.jpg"
    };

    const truncateString = (string: string, length: number) => {
        return string.length > length ? string.substring(0, length - 3) + "..." : string
    }

    async function getNotifications(page: number){
        setError('')
        setLoading(true)
        try{
            const { data } = await api.get('messages', { params: { pageIndex: page }});
            setMessages(data.sentNotifications)
            let pagesArray = Array.from(Array(data.totalPages), (x, index) => index + 1)
            setPages(pagesArray)
        }catch(err){
            const {error} = err.response.data;

            if(error.codeValue === 0)
                history.push('/login')

            if(error.codeValue === 1)
                setError('A página que você está tentando acessar não existe.')
        }
        setLoading(false)
    }
    
    useEffect(() => {
        getNotifications(currentPage)
        window.scrollTo(0, 0);
        //eslint-disable-next-line
    }, [currentPage])

    const handlePageChange = (pageIndex: number) => {
        setCurrentPage(pageIndex)
        history.push(`/dashboard/mensagens/${pageIndex}`)
    }

    return(
        <div className="w-full">
            <Pagination currentPage={currentPage} handlePageChange={handlePageChange} pages={pages}/>
            { loading ? <ListSkeleton />
            : <>
                <ul className="pt-6 space-y-6 w-full">
                    {messages.map(message => <ListItem key={message._id} {...message}>
                        {!!message.notification?.image && message.notification?.image !== "https://www.omeletecompany.com" ? (
                            <div className="list-thumb">
                                <img alt="message-thumbnail" src={message.notification.image}/>
                            </div>) : (<p className="thumb-failsafe">Sem Imagem</p>)}
                        <div className="banner-div absolute top-0 right-0">
                            <img src={!!appBanner[message.topic] ? appBanner[message.topic] : appBanner["Unknown"]}
                                 alt="site-banner" className="banner"/>
                        </div>
                        <div className="flex flex-row justify-between">
                            <div>
                                <TitleH5>{message.notification?.title}</TitleH5>
                                <p className="text-sm text-gray-500 mt-0.5 truncate">{truncateString(message.notification?.body, 80)}

                                </p>
                                <a className="text-sm bg-pink-500 hover:bg-pink-700 text-white p-1 rounded-sm" target="_blank" rel="noreferrer"
                                   href={`https://www.${siteUrlDict[message.app]}${message.notification?.clickAction === '/' ? "" : '/'+message.notification?.clickAction.replace(/\?webPush.*/g, "")}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4" fill="white" viewBox="0 0 24 24">
                                        <path
                                            d="M10.59,13.41C11,13.8 11,14.44 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C7.22,12.88 7.22,9.71 9.17,7.76V7.76L12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.59,9.17C9.41,10.34 9.41,12.24 10.59,13.41M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.78,11.12 16.78,14.29 14.83,16.24V16.24L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L13.41,14.83C14.59,13.66 14.59,11.76 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z"/>
                                    </svg>
                                    {" "+siteUrlDict[message.app]}{message.notification?.clickAction === '/' ? "" : '/'+truncateString(message.notification?.clickAction.replace(/\?webPush.*/g, ""), 80)}
                                </a>
                            </div>
                        </div>
                        <ul className="text-xs flex mt-3 gap-1">
                            <li className="font-bold mr-1">{message.author}</li>
                            <li>{moment(message.createdAt).format('DD/MM/YYYY - HH:mm')}</li>
                        </ul>
                    </ListItem>)}
                </ul>

                {error &&
                    <div className="flex flex-col items-center justify-center">
                        <p className="text-sm text-red-700 text-center mb-4">{error}</p>
                        <Button onClick={() => { handlePageChange(1) }}>Retornar para a listagem</Button>
                    </div>
                }
            </>
            }
            <Pagination currentPage={currentPage} handlePageChange={handlePageChange} pages={pages}/>
        </div>
    )
}

export default MessagesList