type LogoProps = {
    theme: 'theme-dark' | 'theme-light'
}

const Logo = ({ theme } : LogoProps) => (
<header className={`header h-16 w-40 flex items-end text-left ${theme === 'theme-dark' ? 'text-white' : 'text-black'}`}>
    <div className="header__icon">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
        </svg>
    </div>
    <h1 className="ml-1.5 font-mono font-black text-size-x leading-4">PUSH NOTIFICATIONS</h1>
</header>)

export default Logo