type TitleProps = {
    children: React.ReactNode
}

export const TitleH1 = ({children} : TitleProps) => <h1 className="font-black text-5xl text-pink-600">
    {children}
</h1>

export const TitleH2 = ({children} : TitleProps) => <h2 className="font-black text-3xl text-pink-500">
    {children}
</h2>

export const TitleH3 = ({children} : TitleProps) => <h3 className="font-black text-2xl text-pink-500">
    {children}
</h3>

export const TitleH4 = ({children} : TitleProps) => <h4 className="font-black text-xl text-pink-300">
    {children}
</h4>

export const TitleH5 = ({children} : TitleProps) => <h5 className="font-black text-md text-gray-700">
    {children}
</h5>

export const TitleH6 = ({children} : TitleProps) => <h6 className="font-black text-sm text-gray-500">
    {children}
</h6>
