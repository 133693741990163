import {useEffect, useState} from 'react'
import {api} from '../../../../services/api'
import {useHistory, useParams} from 'react-router-dom'

import moment from 'moment';
import 'moment/locale/pt-br';

import {Button} from '../../../../components/UI/Buttons/Buttons'
import {TitleH5} from '../../../../components/UI/Titles/Titles'
import {ListItem, ListSkeleton, Pagination} from '../../../../components/UI/Listing/Listing'

moment.locale('pt-br');

type Topic = {
    _id: number,
    appName: string,
    topicName: string,
    lastSubscription: string,
    created_at: string,
    topicUsers: number
}

const TopicsList = () => {
    const { page } = useParams<{ page: string }>();
    const history = useHistory();

    const [pages, setPages] = useState<any[]>([]);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(Number(page) || 1);
    const [topics, setTopics] = useState<Topic[]>([]);
    const [loading, setLoading] = useState(true);

    const siteDict: ({}|any) = {
        "Unknown": "Omelete Company",
        "Omelete_production": "Omelete",
        "Omelete_staging": "Omelete Staging",
        "Omelete_dev": "Omelete Development",
        "The_Enemy_production": "The Enemy",
        "The_Enemy_staging": "The Enemy Staging",
        "The_Enemy_dev": "The Enemy Development",
        "dev_staging": "Development"
    };

    const getTopicsUsers = async (topics: Topic[]) => {
        await Promise.all(topics.map(async (topic) => {
            const {data} = await api.get('topics/subscription?topicName='+topic.topicName);
            topic.topicUsers = data["topicUsers"];
            return topic;
        }));
        setTopics(topics);
        setLoading(false);
    }

    async function getTopics(page: number){
        setError('');
        setLoading(true);
        try{
            const {data} = await api.get('topics', {params: {pageIndex: page}});
            getTopicsUsers(data.topics).then();
            let pagesArray = Array.from(Array(data.totalPages), (x, index) => index + 1);
            setPages(pagesArray);
        }catch(err){
            const {error} = err.response.data;

            if(error.codeValue === 0)
                history.push('/login')

            if(error.codeValue === 1)
                setError('A página que você está tentando acessar não existe.')
        }
    }

    useEffect(() => {
        getTopics(currentPage).then();
        window.scrollTo(0, 0);
        //eslint-disable-next-line
    }, [currentPage])

    const handlePageChange = (pageIndex: number) => {
        setCurrentPage(pageIndex)
        history.push(`/dashboard/topicos/${pageIndex}`)
    }

    return(
        <div className="w-full">
            <Pagination currentPage={currentPage} handlePageChange={handlePageChange} pages={pages}/>
            { loading ? <ListSkeleton />
            : <>
                <ul className="pt-6 space-y-6 w-full">
                    {topics.map(topic => 
                        <ListItem key={topic._id}>
                            <div className="flex flex-col">
                                <TitleH5>{siteDict[topic.appName]}</TitleH5>
                                {/*<p className="text-sm text-gray-500 mt-0.5">@ {topic.appName}</p>*/}
                                <p className="text-sm text-gray-500 mt-0.5"><span className="text-pink-500 font-bold">{topic.topicUsers}</span> usuários cadastrados</p>
                            </div>
                            <ul className="text-xs flex mt-3 gap-1">
                                <li>Última inscriçao {moment(topic.lastSubscription).fromNow()}</li>
                            </ul>
                        </ListItem>
                    )}
                </ul>

                {error &&
                    <div className="flex flex-col items-center justify-center">
                        <p className="text-sm text-red-700 text-center mb-4">{error}</p>
                        <Button onClick={() => { handlePageChange(1) }}>Retornar para a listagem</Button>
                    </div>
                }
            </>
            }
            <Pagination currentPage={currentPage} handlePageChange={handlePageChange} pages={pages}/>
        </div>
    )
}

export default TopicsList