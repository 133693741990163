import { useHistory } from 'react-router-dom'
import { useGoogleLogout } from 'react-google-login'

const clientId = process.env.REACT_APP_G_CLIENT_ID || ''

const Logout = () => {
    const history = useHistory()
    
    const { signOut } = useGoogleLogout({
        clientId,
        onLogoutSuccess: () => {
            localStorage.removeItem('authToken')
            sessionStorage.removeItem('userPermissions')
            sessionStorage.removeItem('userIcon')
            history.push('/login')
        },
        cookiePolicy: 'single_host_origin'
    })

    return <li className="flex cursor-pointer text-white pt-5 pb-3" onClick={signOut}>
        <span className="mr-2">Logout</span>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
        </svg>
    </li>
}

export default Logout