import {Link} from "react-router-dom";
import {useState} from "react";

const Navigation = () => {
    const userPermissions: any|string = sessionStorage.getItem('userPermissions');

    const [messagesMenuAccess] = useState<boolean>(userPermissions.includes('all') || userPermissions.includes('default') || userPermissions.includes('messages'));
    const [topicsMenuAccess] = useState<boolean>(userPermissions.includes('all') || userPermissions.includes('default') || userPermissions.includes('topics'));
    const [usersMenuAccess] = useState<boolean>(userPermissions.includes('all') || userPermissions.includes('users'));

    return (
        <nav className="navigation sidebar__navigation">
            <ul className="mt-12 text-md">
                {messagesMenuAccess ? (
                    <li className="flex w-full justify-between text-gray-300 hover:text-pink-500 cursor-pointer items-center mb-4">
                        <div className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="h-4 w-4 transform rotate-60 relative -top-1"
                                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"/>
                            </svg>
                            <Link className="ml-2" to="/dashboard/mensagens/1">Mensagens</Link>
                        </div>
                    </li>
                ) : (
                    <></>
                )}
                {topicsMenuAccess ? (
                    <li className="flex w-full justify-between text-gray-300 hover:text-pink-500 cursor-pointer items-center mb-4">
                        <div className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
                            </svg>
                            <Link className="ml-3" to="/dashboard/topicos/1">Tópicos</Link>
                        </div>
                    </li>
                ) : (
                    <></>
                )}
                {usersMenuAccess ? (
                    <li className="flex w-full justify-between text-gray-300 hover:text-pink-500 cursor-pointer items-center mb-4">
                        <div className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M15.573,11.624c0.568-0.478,0.947-1.219,0.947-2.019c0-1.37-1.108-2.569-2.371-2.569s-2.371,1.2-2.371,2.569c0,0.8,0.379,1.542,0.946,2.019c-0.253,0.089-0.496,0.2-0.728,0.332c-0.743-0.898-1.745-1.573-2.891-1.911c0.877-0.61,1.486-1.666,1.486-2.812c0-1.79-1.479-3.359-3.162-3.359S4.269,5.443,4.269,7.233c0,1.146,0.608,2.202,1.486,2.812c-2.454,0.725-4.252,2.998-4.252,5.685c0,0.218,0.178,0.396,0.395,0.396h16.203c0.218,0,0.396-0.178,0.396-0.396C18.497,13.831,17.273,12.216,15.573,11.624 M12.568,9.605c0-0.822,0.689-1.779,1.581-1.779s1.58,0.957,1.58,1.779s-0.688,1.779-1.58,1.779S12.568,10.427,12.568,9.605 M5.06,7.233c0-1.213,1.014-2.569,2.371-2.569c1.358,0,2.371,1.355,2.371,2.569S8.789,9.802,7.431,9.802C6.073,9.802,5.06,8.447,5.06,7.233 M2.309,15.335c0.202-2.649,2.423-4.742,5.122-4.742s4.921,2.093,5.122,4.742H2.309z M13.346,15.335c-0.067-0.997-0.382-1.928-0.882-2.732c0.502-0.271,1.075-0.429,1.686-0.429c1.828,0,3.338,1.385,3.535,3.161H13.346z"/>
                            </svg>
                            <Link className="ml-3" to="/dashboard/usuarios/1">Usuários</Link>
                        </div>
                    </li>
                ) : (
                    <></>
                )}
            </ul>
        </nav>
    )
}

export default Navigation