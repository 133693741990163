import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    Box,
    Button as MaterialButton,
    createTheme,
    InputAdornment,
    MuiThemeProvider,
    OutlinedInput,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import {TitleH3} from '../../../../components/UI/Titles/Titles';
import {api} from '../../../../services/api';
import {toast} from 'react-toastify';
import Modal from 'react-modal';
import {PushPreview} from "../../../../components/UI/PushPreview/PushPreview";

type Topic = {
    _id: number,
    appName: string,
    topicName: string
}

const customStyles = {
    overlay: {
        background: 'rgba(0,0,0,.5)'
    },
    content: {
        width: '950px',
        maxHeight: '98vh',
        maxWidth: 'calc(100% - 40px)',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};

type CreateMessageProps = {
    closeModal: () => void,
    visibility: boolean
}

const siteDict: ({}|any) = {
    "Unknown": "omeletecompany",
    "Omelete_production": "Omelete",
    "Omelete_staging": "Omelete Staging",
    "Omelete_dev": "Omelete Development",
    "The_Enemy_production": "The Enemy",
    "The_Enemy_staging": "The Enemy Staging",
    "The_Enemy_dev": "The Enemy Development"
};

const CreateMessage = ({closeModal, visibility}: CreateMessageProps) => {
    const history = useHistory();

    const [message, setMessage] = useState<any | {} | undefined>({ app: "Unknown" })
    const [notification, setNotification] = useState({title: "", text: "",  action: "", icon: "", image: ""})
    const [topics, setTopics] = useState<Topic[]>([])
    const [topicUsers, setTopicUsers] = useState<number>(0)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const modalWarningElement: any = !!document.querySelector('.confirm-modal-close') ? document.querySelector('.confirm-modal-close') : null;

    const theme = createTheme({
        overrides: {
            MuiStepIcon: {
                root: {
                    '&$completed': {
                        color: 'pink',
                    },
                    '&$active': {
                        color: 'rgba(236, 72, 153)',
                    },
                }
            },
            MuiButton: {
                root: {
                    marginTop: "10px",
                    marginRight: "10px"
                }
            },
            MuiStepper: {
                root: {
                    paddingLeft: "0"
                }
            },
            MuiOutlinedInput: {
                root: {
                    padding: "0",
                    height: "38px",
                    width: "100%"
                },
                input: {
                    padding: "0.5em 0.75em"
                }
            }
        },
        palette: {
            primary: {
                main: 'rgba(236, 72, 153)',
                dark: 'rgb(190, 24, 93)'
            },
            secondary: {
                main: '#2563eb'
            }
        }
    })

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setError("");
    };


    //TODO: Check for 401 error
    const getTopics = async () => {
        const {data} = await api.get('topics');

        setTopics(data["topics"]);
    }

    //TODO: Check for 401 error
    const getTopicUsers = async (topicName: string) => {
        const {data} = await api.get('topics/subscription?topicName='+topicName);

        setTopicUsers(data["topicUsers"]);
    }

    useEffect(() => {
        getTopics().then();
        setMessage({
            author: "Unknown",
            app: "Unknown",
            topic: "InitialValue"
        });
    }, [])

    const requestCloseModal = () => {
        if (!!modalWarningElement) {
            if (modalWarningElement.classList.contains("hidden")) {
                modalWarningElement.classList.remove("hidden");
            } else {
                shakeWarning();
            }
        } else {
            forceCloseModal();
        }
    }

    const shakeWarning = () => {
        setTimeout(() => {
            modalWarningElement.classList.remove("shake-content");
        }, 820);
        modalWarningElement.classList.add("shake-content");
    }

    const cancelCloseModal = () => {
        modalWarningElement.classList.add("hidden");
    }

    const forceCloseModal = () => {
        setError("");
        setNotification({
            title: "",
            text: "",
            action: "",
            icon: "",
            image: ""
        });
        setMessage({
            topic: "InitialValue",
            app: "Unknown"
        });
        setTopicUsers(0);
        setActiveStep(0);
        closeModal();
    }

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            if (message.topic === 'Unknown') {
                throw new Error('Nenhum tópico foi selecionado')
            }

            await api.post('messages/send', {
                ...message,
                notification
            });

            toast.success("Mensagem enviada");
            setActiveStep(0);
            forceCloseModal();
        } catch (err) {
            const {error} = err.response.data;

            if(!!error.codeValue && error.codeValue === 0)
                history.push('/login')

            setError(error.message || 'Houve um erro inesperado ao tentar enviar a mensagem. Tente novamente.');
        }

        setLoading(false);
    }

    // Function to ensure that topics value is not undefined
    const ensure = function <T>(argument: T | undefined | null, message: string = 'This value was promised to be there.'): T {
        if (argument === undefined || argument === null) {
            throw new TypeError(message);
        }

        return argument;
    }

    return (
        <Modal
            isOpen={visibility}
            contentLabel="Example Modal"
            onRequestClose={() => requestCloseModal}
            style={customStyles}
            ariaHideApp={false}
        >
            <div className="confirm-modal-close hidden flex absolute bottom-6">
                <span className="pt-1">Tem certeza que deseja fechar a criação de mensagem? Você perderá todos os dados!</span>
                <div className="ml-auto flex">
                    <button className="mr-4 hover-underline" onClick={cancelCloseModal}>Cancelar</button>
                    <button className="py-1 px-2 rounded-sm bg-pink-500 hover:bg-pink-600" onClick={forceCloseModal}>Fechar mesmo assim</button>
                </div>
            </div>
            <button onClick={requestCloseModal} className="absolute top-1.5 right-1.5">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
            <div className="w-full">
                <TitleH3>Enviar nova mensagem</TitleH3>
                <MuiThemeProvider theme={theme}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        <Step key='Notificação'>
                            <StepLabel>Notificação</StepLabel>
                            <StepContent>
                                <Box sx={{mb: 2}}>
                                    <div className="flex">
                                        <form className="bg-white rounded px-2 p1-6 pb-2 container flex-shrink">
                                            <label className="block text-gray-500 text-sm mb-1">
                                                Título da notificação (preferencialmente até 40 caracteres)
                                            </label>
                                            <input
                                                value={notification.title.toString()}
                                                className="appearance-none border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-5 leading-tight focus:outline-none focus:shadow-outline"
                                                type="text"
                                                required
                                                onChange={e => setNotification({...notification, 'title': e.target.value})}/>

                                            <label className="block text-gray-500 text-sm mb-1">
                                                Texto da notificação (preferencialmente até 250 caracteres)
                                            </label>
                                            <textarea
                                                value={notification.text.toString()}
                                                className="appearance-none resize-none border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-5 leading-tight focus:outline-none focus:shadow-outline h-32"
                                                onChange={e => setNotification({...notification, 'text': e.target.value})}/>

                                            <label className="block text-gray-500 text-sm mb-1">
                                                Pra quem irá aparecer
                                            </label>
                                            <select className="order border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-5 leading-tight"
                                                    required
                                                    defaultValue={message.topic}
                                                    onChange={event => {
                                                        const topic = ensure(topics.find((topic) => topic.topicName === event.target.value))
                                                        setMessage({...message, 'topic': topic.topicName, 'app': topic.appName})
                                                        setNotification({...notification, 'icon': siteDict[topic.appName.toString()]})
                                                        getTopicUsers(topic.topicName.toString()).then();
                                                    }}>
                                                <option value="InitialValue" disabled>Selecione uma opção</option>
                                                {topics.map((topic) => <option key={topic._id}
                                                                               value={topic.topicName}>{siteDict[topic.appName.toString()] ? siteDict[topic.appName.toString()] : topic.appName}</option>)}
                                            </select>

                                            <label className="block text-gray-500 text-sm mb-1">
                                                Caminho do click (opcional)
                                            </label>
                                            <OutlinedInput
                                                className="focus:outline-none focus:shadow-outline material-input mb-5"
                                                id="outlined-adornment-weight"
                                                color="secondary"
                                                value={notification.action.toString()}
                                                onChange={(e: any) => setNotification({...notification, 'action': e.target.value})}
                                                startAdornment={<InputAdornment position="start">{siteDict[message.app] ? siteDict[message.app].replace(/\s/g, '').toLowerCase() : message.app.replace(/_/g, '').toLowerCase()}.com.br/</InputAdornment>}
                                            />


                                            <label className="block text-gray-500 text-sm mb-1">
                                                Url da imagem (opcional)
                                            </label>
                                            <input
                                                value={notification.image.toString()}
                                                className="appearance-none border-gray-300 border rounded w-full py-2 px-3 text-gray-700 mb-0 leading-tight focus:outline-none focus:shadow-outline"
                                                type="text"
                                                onChange={e => setNotification({...notification, 'image': e.target.value})}/>
                                            <p className="text-gray-500 text-sm font-extralight">A url deve começar com 'http...'</p>
                                        </form>
                                        <section className="rounded border border-gray-300 preview ml-5 bg-gray-100">
                                            <Typography className="text-center pt-2 text-gray-500">Preview</Typography>

                                            <PushPreview notification={{
                                                type: "chrome",
                                                app: message.app.toString(),
                                                ...notification
                                            }}/>
                                            <p className="text-center text-gray-400 font-light mb-4">macOS e Linux Chrome</p>

                                            <PushPreview notification={{
                                                type: "firefox",
                                                app: message.app.toString(),
                                                ...notification
                                            }}/>
                                            <p className="text-center text-gray-400 font-light mb-4">macOS e Linux Firefox</p>

                                            <PushPreview notification={{
                                                type: "windows",
                                                app: message.app.toString(),
                                                ...notification
                                            }}/>
                                            <p className="text-center text-gray-400 font-light mb-4">Windows</p>
                                        </section>
                                    </div>
                                    <MaterialButton variant="contained" color="primary" onClick={handleNext} disabled={notification.title === "" || notification.text === "" || message.topic === "InitialValue"} >
                                        Próxima
                                    </MaterialButton>
                                </Box>
                            </StepContent>
                        </Step>
                        {/*STEP PROGRAMAÇÃO, SERÁ USADO NO FUTURO*/}
                        {/*<Step key='Programação'>*/}
                        {/*    <StepLabel>Programação</StepLabel>*/}
                        {/*    <StepContent>*/}
                        {/*        <Typography>Selecione um dia e horário específicos para enviar a notificação.</Typography>*/}
                        {/*        <Box sx={{mb: 2}}>*/}
                        {/*            <div>*/}
                        {/*                <MaterialButton variant="contained" color="primary" onClick={handleNext}>*/}
                        {/*                    Próxima*/}
                        {/*                </MaterialButton>*/}
                        {/*                <MaterialButton onClick={handleBack}>*/}
                        {/*                    Voltar*/}
                        {/*                </MaterialButton>*/}
                        {/*            </div>*/}
                        {/*        </Box>*/}
                        {/*    </StepContent>*/}
                        {/*</Step>*/}
                        <Step key='Resumo'>
                            <StepLabel>Resumo</StepLabel>
                            <StepContent>
                                <Typography>Revise sua notificação antes de enviá-la</Typography>
                                <section className="rounded border border-gray-300 bg-gray-100 preview">
                                    <Typography className="text-center pt-2 text-gray-500">Preview</Typography>

                                    <PushPreview notification={{
                                        type: "chrome",
                                        app: message.app.toString(),
                                        ...notification
                                    }}/>
                                    <p className="text-center text-gray-400 font-light mb-4">macOS e Linux Chrome</p>

                                    <PushPreview notification={{
                                        type: "firefox",
                                        app: message.app.toString(),
                                        ...notification
                                    }}/>
                                    <p className="text-center text-gray-400 font-light mb-4">macOS e Linux Firefox</p>

                                    <PushPreview notification={{
                                        type: "windows",
                                        app: message.app.toString(),
                                        ...notification
                                    }}/>
                                    <p className="text-center text-gray-400 font-light mb-4">Windows</p>
                                </section>
                                <Typography className="notification-summary">Esta notificação irá impactar <span>{topicUsers}</span> usuários</Typography>
                                <Box sx={{mb: 2}}>
                                    <div>
                                        <MaterialButton variant="contained" color="primary" disabled={loading} onClick={handleSubmit}>
                                            Enviar
                                        </MaterialButton>
                                        <MaterialButton onClick={handleBack}>
                                            Voltar
                                        </MaterialButton>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    </Stepper>
                </MuiThemeProvider>

                {error &&
                <div className="flex items-center justify-center">
                    <p className="text-sm text-red-700 text-center">{error}</p>
                </div>
                }
            </div>
        </Modal>
    )
}

export default CreateMessage;