import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { TitleH3 } from '../../components/UI/Titles/Titles'
import GoogleLoginButton from '../../components/GoogleLogin/GoogleLogin'
import Logo from '../../components/UI/Logo/Logo'
import { api } from '../../services/api';
import jwtDecode from 'jwt-decode';

function Login () {
    const history = useHistory();
    const [error, setError] = useState('');

    const handleLoginSuccess = (res: any) => {
        try{
            //Setting authorization header with generated token to all requests
            api.defaults.headers.common['Authorization'] = `Bearer ${res.credential}`;
            const userInfo = jwtDecode(res.credential) as any;
            sessionStorage.setItem('userIcon', userInfo.picture);
            api.post('users/authenticate', {payload: res}).then((result) => {
                setError('');
                localStorage.setItem('authToken', res.credential);
                sessionStorage.setItem('userPermissions', result.data.permissions);
                console.log(result);
                history.push('/dashboard');
            }).catch((error) => {
                if (error.response.status === 401) {
                    setError('Desculpe, não foi possível realizar o login. Verifique se você possui permissão para acessar esta plataforma.')
                } else {
                    setError('Desculpe, não foi possível realizar o login.')
                }
            });
        } catch (error) {
            setError('Desculpe, não foi possível realizar o login.');
            history.push('/login');
        }
    };

    return (<section className="w-full h-screen flex flex-col items-center justify-center bg-gray-800">
        <div className="mb-8 ml-4">
            <Logo theme='theme-dark'/>
        </div>
        <form className="bg-white max-w-xs rounded p-4 container shadow" action="/dashboard">
                <div className="text-center mb-6 mt-4">
                    <TitleH3>Login</TitleH3>
                    <p className="text-sm text-gray-400 mt-2">Entre com sua conta @omeletecompany</p>
                </div>
                
                <div className="text-center mb-6 mt-4 justify-center flex items-center">
                    <GoogleLoginButton onSuccess={ handleLoginSuccess }/>
                </div>

                {error &&
                    <div className="flex items-center justify-center">
                        <p className="text-sm text-red-700 text-center">{error}</p>
                    </div>
                }
                
            </form>
    </section>)
}

export default Login