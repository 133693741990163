import Logo from '../UI/Logo/Logo'
import Navigation from './Navigation/Navigation'
import Logout from '../UI/Logout/Logout'

const avatarStyle = {
    borderRadius: '50%',
    height: '2.5em',
    alignSelf: 'center'
}
type ProfileProps = {
    profilePicture: string
}

const Sidebar = ({profilePicture}: ProfileProps) => {
    return (
        <div className="flex flex-no-wrap sticky h-screen top-0">
            {/* Sidebar starts */}
            {/* Remove class [ hidden ] and replace [ sm:flex ] with [ flex ] */}
            <div className="w-56 bg-gray-800 shadow md:h-full flex-col justify-between hidden sm:flex">
                <div className="px-4">
                    <Logo theme="theme-dark"/>
                    <Navigation/>
                </div>
                <div className="flex px-4 border-t border-gray-700 justify-evenly">
                    <img className="avatar"
                         src={profilePicture}
                         alt="user avatar"
                         style={avatarStyle}
                    />
                    <div className="flex items-center justify-end bg-gray-800">
                        <Logout/>
                    </div>
                </div>
            </div>
            {/* Sidebar ends */}
        </div>
        // <Header/>
        // <Navigation/>
    )
}

export default Sidebar